import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import Assets from "../../assets/Assets";
import Style from "./Auth.module.scss";

const AuthModule = () => {
  const emailRef = useRef("");
  return (
    <>
      <div className={Style.root}>
        <div className={Style.privacy}>
          <Link className={Style.link} to="/privacypolicy">
            Privacy policy
          </Link>
        </div>
        <div className={Style.designed_by}>
          Designed by
          <Link
            className={Style.link}
            to="https://webandcrafts.com/?utm_source=bapplapps.com&utm_medium=referral&utm_campaign=bapplapps.com_referral_wac_client"
          >
            webandcrafts
          </Link>
        </div>
        <Image
          src={Assets?.AUTH_CIRCLES}
          width={1061}
          height={1057}
          alt={"circles"}
          propStyle={{ root: Style.circles }}
        />
        <Image
          src={Assets.AUTH_MAN}
          width={575}
          height={812}
          propStyle={{ root: Style.img_man }}
        />
        <div className={Style.root_inner}>
          <div className={`${Style.logoWrapper} pro-pb-5`}>
            <Image
              src={Assets.HEADERLOGO}
              width={99.35}
              height={31.29}
              alt={`BAPPL Logo`}
            />
          </div>
          <div className={`${Style.auth} pro-pt-3`}>
            <Outlet context={{ emailRef }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthModule;
