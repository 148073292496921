import { useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getUserPhoneNumbers, getUserDetails, getUserOtp } from "../api";
import { updateConfig } from "../../../store/slices/UserVerification/UserVerificationSlice";
import { toast } from "react-toastify";

const useUserVerificationMain = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.userVerification);
  const { selectedUserDetails } = useSelector(
    (state) => state.userVerification
  );
  const User_Details = localStorage.getItem("USER_DETAILS");
  const [seconds, setSeconds] = useState(60);
  const [userDataFetched, setUserDataFetched] = useState(false);
  const [verifyOtpModal, setVerifyOtpModal] = useState(false);

  const validation = Yup.number({
    phone: Yup.string()
      .matches(/^\d{8,12}$/, "Phone number must be between 8 and 12 digits")
      .required("A phone number is required"),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
      selectedPhone: "",
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      getUserDetails(menuState.selectedUserId ?? User_Details?.id).then(
        (response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);
            setUserDataFetched(true);
            dispatch(
              updateConfig((state) => {
                state.selectedUserDetails = response?.data?.data;
              })
            );
            localStorage.setItem(
              "USER_DETAILS",
              JSON.stringify(response?.data?.data[0])
            );
          } else {
            toast.error(
              response?.data?.message ?? "Problem while fetching user details"
            );
          }
        }
      );
    },
  });

  const handleInputChange = (item) => {
    if (item?.length > 0) {
      getUserPhoneNumbers({ search: item }).then((response) => {
        dispatch(
          updateConfig((state) => {
            state.userPhoneNumbers = response?.data?.data;
          })
        );
      });
    } else {
      dispatch(
        updateConfig((state) => {
          state.userPhoneNumbers = [];
        })
      );
    }
  };

  const handleUserNumber = (value) => {
    formik.setFieldValue("selectedPhone", value?.mobile_number);
    dispatch(
      updateConfig((state) => {
        state.selectedUserId = value?.id;
      })
    );
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    getUserOtp({
      user_id: selectedUserDetails[0]?.id ?? User_Details?.id,
    }).then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        setVerifyOtpModal(true);
        setSeconds(60);
        dispatch(
          updateConfig((state) => {
            state.userVerificationOtp = response?.data?.data?.debug;
          })
        );
      }
    });
  };

  const setCloseVerifyOtpModal = () => {
    setVerifyOtpModal(false);
  };

  return {
    formik,
    handleSubmitOtp,
    // mainData,
    menuState,
    selectedUserDetails,
    userDataFetched,
    verifyOtpModal,
    seconds,
    optionsData: menuState.userPhoneNumbers,
    setCloseVerifyOtpModal,
    handleUserNumber,
    handleInputChange,
    setVerifyOtpModal,
    setSeconds,
  };
};

export default useUserVerificationMain;
