import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Global";

import { markNotificationRead } from "./api";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const activeUser = useMemo(
    () => localStorage.getItem("VENDOR_DETAILS") ?? [],
    []
  );

  const [isProfileViewVisible, setProfileViewVisible] = useState(false);
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);
  // const subAdminState = useSelector((state) => state.subAdmins);

  const UserDetails = JSON.parse(localStorage?.getItem("VENDOR_DETAILS"));

  useEffect(() => {
    if (isProfileViewVisible)
      dispatch(
        updateConfig((state) => {
          state.isProfileViewVisible = false;
          setProfileViewVisible(false);
        })
      );
    //eslint-disable-next-line
  }, [isProfileViewVisible]);

  const handleReadNotification = (isNewNotification) => {
    if (isNewNotification) markNotificationRead().then((response) => {});
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen((state) => !state);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchInputRef]);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  const handleViewProfile = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = true;
      })
    );
  };

  const handleCloseProfileView = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = false;
      })
    );
  };

  const handleLogout = () => {
    localStorage.removeItem("VENDOR_DETAILS");
    localStorage.removeItem("USER_DETAILS");
    localStorage.removeItem("USER_ACCESS_TOKEN");
    localStorage.removeItem("dashboard_permissions");

    navigate("/");
  };

  const handleSettings = () => {
    navigate("/settings/appearance");
  };

  const handleProfileRedirect = (userId) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), userId]
      : [userId];

    const newIds = ids.filter((id) => id !== userId).join(",");

    window.open(
      `${window.location.origin}/${`profile?active=${userId}&id=${newIds}`}`
    );
  };

  const handleNotificationRedirect = () => {
    // refetch?.();
    navigate("/notifications");

    // updateNotification({ read_status: 1 }).then((response) => {
    //   if (response?.data?.status_code === 200) {
    //     dispatch(
    //       getNotificationData({
    //         page: page,
    //         notification_id: "notificationData",
    //       })
    //     );
    //   }
    // });
  };

  return {
    isModalOpen,
    searchInputRef,
    globalState,
    // subAdminState,
    userData: globalState?.searchData?.[activeUser],
    handleViewProfile,
    UserDetails,
    setIsModalOpen,
    closeModal,
    handleLogout,
    handleProfileRedirect,
    handleSettings,
    handleCloseProfileView,
    setProfileViewVisible,
    handleReadNotification,
    handleNotificationRedirect,
  };
};

export default useHeaderActions;
