import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { submitUserVerification } from "../../../../store/slices/UserVerification/UserVerificationSlice";
import { pendingRequests } from "../../../../store/queries/pendingRequests";

const useOfferOtpVerificationModal = ({ setSeconds }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageUploadRef = useRef(null);
  const { offerDetailsData } = useSelector((state) => state.userVerification);
  const { selectedUserDetails } = useSelector(
    (state) => state.userVerification
  );
  const [imageUploadPreview, setImageUploadPreview] = useState("");
  const User_Details = localStorage.getItem("USER_DETAILS");
  const { finalSubmitLoading } = useSelector((state) => state.userVerification);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (offerDetailsData?.vendor_type === "CASH") {
      formik?.setFieldValue("redeemed_points", offerDetailsData?.total_points);
    }
    //eslint-disable-next-line
  }, [offerDetailsData]);

  const validation = Yup.object({
    otp: Yup.number()
      .typeError("Please enter a valid OTP")
      .positive("OTP must be a positive number")
      .integer("OTP must be an integer")
      .min(100000, "OTP must be at least 6 digits")
      .max(999999, "OTP must be at most 6 digits")
      .required("OTP is required"),
    reward: Yup.number(),
    cash: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
      reward: "",
      redeemed_points: "",
      price: "",
      cash: 0,
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: (values, { resetForm }) => {
      const obj = {
        user_id: selectedUserDetails[0]?.id ?? User_Details?.id,
        vendor_otp: values?.otp,
        offer_id: offerDetailsData?.selected_offer,
        entered_point: values?.redeemed_points,
        reward_id:
          offerDetailsData?.vendor_type === "CASH"
            ? offerDetailsData?.rewards?.[0]?.reward_id
            : values?.reward,
        cash:
          offerDetailsData?.vendor_type === "CASH"
            ? offerDetailsData?.rewards?.[0]?.remaining_cash
            : values?.cash ?? 0,
      };
      let formData = new FormData();

      if (values?.image?.name) {
        obj.image = values?.image;
      }

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      dispatch(submitUserVerification({ data: formData })).then((res) => {
        if (res?.payload?.statusCode === 200) {
          dispatch(pendingRequests.util.invalidateTags(["PendingRequests"]));
          toast.success(res?.payload?.message);
          navigate("/user-verification");
          localStorage.removeItem("USER_DETAILS");
          localStorage.removeItem("dashboard_permissions");
        } else {
          toast.error(res?.payload?.message);
        }
      });
    },
  });

  const handleChangeReward = (value) => {
    formik.setFieldValue("reward", value?.reward_id || null);
    formik.setFieldValue("price", value?.price);
    formik.setFieldValue("redeemed_points", offerDetailsData?.total_points);
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUploadPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  return {
    formik,
    offerDetailsData,
    imageUploadRef,
    imageUploadPreview,
    finalSubmitLoading,
    handleChangeReward,
    handleImageUpload,
  };
};

export default useOfferOtpVerificationModal;
