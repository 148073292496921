import React from "react";
import {
  IconText,
  Image,
  Input,
  Button,
} from "@wac-ui-dashboard/wac_component_library";
import useOfferOtpVerificationModal from "./useOfferOtpVerificationModal";
import Select from "react-select";
import { limitStrLength } from "../../../../utils/functions/table";

const OfferOtpVerificationModal = ({
  seconds,
  setSeconds,
  handleSendOfferOtp,
  setCloseVerifyVendorOtpModal,
}) => {
  const {
    formik,
    offerDetailsData,
    imageUploadRef,
    imageUploadPreview,
    finalSubmitLoading,
    handleChangeReward,
    handleImageUpload,
  } = useOfferOtpVerificationModal({ setSeconds });

  return (
    <div>
      <Input
        label={"OTP*"}
        type="text"
        id="otp"
        name="otp"
        placeholder="Enter OTP "
        className={`pro-input lg ${
          formik.errors.otp && formik.touched.otp && " error"
        }`}
        {...formik.getFieldProps("otp")}
        error={formik.errors.otp && formik.touched.otp}
        errorMessage={formik.errors.otp}
      />
      <div className="pro-d-flex pro-justify-between pro-mb-4">
        <span className="pro-font-sm">
          Time Remaining: <span className="pro-fw-medium">{seconds} sec</span>
        </span>
        <button
          className="pro-btn-link"
          onClick={(e) => (seconds > 0 ? "" : handleSendOfferOtp(e))}
        >
          Resend OTP
        </button>
      </div>

      {offerDetailsData?.vendor_type === "CASH" && (
        <div>
          <Input
            label={"Reward"}
            id="offer_type "
            name="offer_type "
            disabled={true}
            value={offerDetailsData?.vendor_type}
          />
        </div>
      )}

      {offerDetailsData?.vendor_type === "OFFER" && (
        <div>
          <Input
            label={"Offer"}
            id="offer"
            name="offer"
            disabled={true}
            value={offerDetailsData?.offer?.name}
          />
        </div>
      )}

      {offerDetailsData?.vendor_type !== "CASH" && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Reward
          </label>
          <Select
            id="reward"
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.reward && formik.errors.reward && " error"
            }`}
            classNamePrefix="pro-input"
            options={offerDetailsData?.rewards}
            getOptionValue={(option) => option?.reward_id}
            getOptionLabel={(option) => option?.reward_name}
            value={offerDetailsData?.rewards?.filter(
              (m) => formik?.values?.reward === m?.reward_id
            )}
            onBlur={formik.handleBlur("reward")}
            onChange={(value) => {
              handleChangeReward(value);
              formik.setFieldValue("reward", value?.reward_id || null);
              formik.setFieldValue("price", value?.price);
              formik.setFieldValue("redeemed_points", value?.points_to_redeem);
              formik?.setFieldValue("cash", value?.remaining_cash);
            }}
          />
          {formik.touched.reward &&
            formik.errors.reward &&
            !formik?.values?.reward && (
              <span className="error-text">{formik.errors.reward}</span>
            )}{" "}
        </div>
      )}
      {offerDetailsData?.vendor_type !== "CASH" && (
        <Input
          label={"Price"}
          id="price"
          name="price"
          disabled={true}
          value={formik?.values?.price}
        />
      )}

      <Input
        label={"Redeemed Points"}
        id="redeemed_points"
        name="redeemed_points"
        disabled={true}
        value={formik?.values?.redeemed_points}
      />

      {offerDetailsData?.vendor_type !== "CASH" && (
        <Input
          label={"Cash "}
          type="text"
          id="cash"
          name="cash"
          placeholder="Enter cash "
          className={`pro-input lg ${
            formik.errors.cash && formik.touched.cash && " error"
          }`}
          value={formik?.values?.cash}
          error={formik.errors.cash && formik.touched.cash}
          errorMessage={formik.errors.cash}
        />
      )}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Upload Product Image *
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={imageUploadRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleImageUpload(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.image?.name !== undefined
                  ? limitStrLength(formik?.values?.image?.name, 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched?.image && formik?.errors?.image && (
            <span className="error-text">{formik?.errors?.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        {/* <div className={`${Style.root_image_inner}`}> */}
        <div>
          <Image
            width={100}
            height={100}
            src={imageUploadPreview}
            alt={`offer-01`}
          />
        </div>
      </div>

      <div className="pro-d-flex pro-justify-end pro-pt-4 pro-border-top pro-mt-4">
        <Button
          className={`pro-btn-link`}
          onClick={() => setCloseVerifyVendorOtpModal()}
        >
          {" "}
          Cancel
        </Button>
        <Button
          disabled={finalSubmitLoading === true}
          className={`pro-btn-primary pro-ms-3`}
          onClick={() => formik.handleSubmit()}
        >
          {" "}
          Submit
        </Button>
      </div>
    </div>
  );
};

export default OfferOtpVerificationModal;
