import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { addDays, subDays } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetPriceUpdationDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/priceUpdation";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/PriceUpdation/PriceUpdationSlice";

const usePriceUpdationListing = () => {
  const dispatch = useDispatch();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const menuState = useSelector((state) => state.priceUpdation);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPriceUpdationDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page_no: menuState.currentPage,
    // start_date: getFormatedDate(menuState?.start),
    // end_date: getFormatedDate(menuState?.end),
    // is_active: menuState.is_active
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handlePageSize = ({ value: currentPageSize }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = currentPageSize;
        state.currentPage = 1;
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      image: (field, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image)}
            />
          </div>
        );
      },
      action: (field, data) => (
        <span
          onClick={() => handleEditClick(data)}
          style={{ cursor: "pointer" }}
          className="material-symbols-outlined x4"
        >
          edit_square
        </span>
      ),
      status: (field, data) => {
        return data[field] === "Pending" ? (
          <span className="pro-badge badge-warning pro-d-inline-flex pro-items-center">
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.8571 6C12.8571 9.31371 10.1708 12 6.85712 12C3.54341 12 0.857117 9.31371 0.857117 6C0.857117 2.68629 3.54341 0 6.85712 0C10.1708 0 12.8571 2.68629 12.8571 6ZM5.85712 3C5.85712 2.44772 6.30483 2 6.85712 2C7.4094 2 7.85712 2.44772 7.85712 3V6C7.85712 6.55228 7.4094 7 6.85712 7C6.30483 7 5.85712 6.55228 5.85712 6V3ZM6.85712 8C6.30483 8 5.85712 8.44771 5.85712 9C5.85712 9.55229 6.30483 10 6.85712 10C7.4094 10 7.85712 9.55229 7.85712 9C7.85712 8.44771 7.4094 8 6.85712 8Z"
                fill="#AB6100"
              />
            </svg>
            <span>Pending</span>
          </span>
        ) : data[field] === "Declined" ? (
          <span className="pro-badge badge-danger pro-d-inline-flex pro-items-center">
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 12.2316C9.31371 12.2316 12 9.54528 12 6.23157C12 2.91786 9.31371 0.231567 6 0.231567C2.68629 0.231567 0 2.91786 0 6.23157C0 9.54528 2.68629 12.2316 6 12.2316ZM3 5.23157C2.44772 5.23157 2 5.67928 2 6.23157C2 6.78385 2.44772 7.23157 3 7.23157H9C9.55229 7.23157 10 6.78385 10 6.23157C10 5.67928 9.55229 5.23157 9 5.23157H3Z"
                fill="#DD2B0E"
              />
            </svg>
            <span>Declined</span>
          </span>
        ) : data[field] === "Success" ? (
          <span className="pro-badge badge-success pro-d-inline-flex pro-items-center">
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 12.3928C9.31371 12.3928 12 9.70647 12 6.39276C12 3.07905 9.31371 0.392761 6 0.392761C2.68629 0.392761 0 3.07905 0 6.39276C0 9.70647 2.68629 12.3928 6 12.3928ZM8.57617 5.3729C8.84134 5.05469 8.79835 4.58177 8.48014 4.3166C8.16193 4.05142 7.68901 4.09442 7.42383 4.41262L5.4496 6.7817L4.53033 5.86243C4.23744 5.56954 3.76256 5.56954 3.46967 5.86243C3.17678 6.15532 3.17678 6.6302 3.46967 6.92309L4.96967 8.42309C5.11867 8.57209 5.32348 8.65154 5.53399 8.64199C5.74449 8.63244 5.94127 8.53478 6.07617 8.3729L8.57617 5.3729Z"
                fill="#108548"
              />
            </svg>
            <span>Success</span>
          </span>
        ) : (
          ""
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const handleEditClick = (data) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedItemsDetails = data;
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const closeDataEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
        state.start = subDays(new Date(), 30);
        state.end = new Date();
      })
    );
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  return {
    menuState,
    mainData,
    // hasDeletePermission,
    // hasUpdatePermission,
    isFetching,
    currentPage: menuState.currentPage,
    paginationOptions,
    showEditModal,
    actionOptions,
    date,
    isLoading,
    showImageModal,
    imageData,
    closeImageModal,
    handleClearClick,
    updateTableFields,
    handleSearch,
    handleActionChange,
    handleSort,
    closeDataEditModal,
    closeEditModal,
    handlePagination,
    handlePageSize,
    getRow,
    refetch,
    handleDateRangeChange,
  };
};

export default usePriceUpdationListing;
